@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@200&family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css?family=Silkscreen');

.App {
  text-align: center;
  position: relative;
  width: 100%;
}


