.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: auto;
    font-size: 1.2rem;
    margin-bottom: 20vh;

    h2 {
        font-family: 'Silkscreen', 'sans-serif';
        margin-bottom: 0;
    }

    p {
        font-family: 'Poiret One', cursive;
        font-weight: 600;
    }

    .link__wrapper {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        gap: 5px;
        padding-top: 1rem;
    }

    a {
        color: #282c34;
        font-size: 1.5rem;
        font-family: 'Montserrat', cursive;
        font-weight: 600;
        transition: opacity 0.4s ease-in-out;

        &:hover {
            opacity: 0.5;
        }

        .linkedin {
            &-i {
                color: #282c34;
                position: relative;
                top: 3px;
                left: 1px;
            }
        }


    }
}

//Iphone
@media screen and (max-width: 350px) {
    .margin {
        height: 55vh;

        h2 {
            font-size: 1.2rem;
        }

        .content {
            width: 15rem;
            font-size: .8rem;
            line-height: 180%;
        }
    }
}

//Iphone
@media screen and (min-width: 351px) and (max-width: 599px) {
    .contact {
        height: 55vh;
    }
}