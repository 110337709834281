.home {
    position: relative;
    height: 200vh;
    font-family: 'Montserrat', sans-serif;
    background: #e0e0e0;
    z-index: -100;

    .main-title {
        height: 100vh;
        width: 100vw;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            font-family: 'Poiret One', cursive;
            white-space: nowrap;
            color: #2e2e2e;


            span {
                font-family: 'Silkscreen', 'sans-serif';
            }
        }

        img {
            font-family: 'Montserrat', sans-serif;
            padding: 1rem;
            color: #2e2e2e;
            box-sizing: border-box;
        }
    }

    .banner {
        width: 100%;
        height: 100vh;
        overflow: hidden;

        img {
            height: 100vh;
            width: 100%;
            overflow: hidden;
            object-fit: cover;
            box-sizing: border-box;
        }

        .text-at-bottom {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            font-weight: 600;
            color: #000000;

            p {
                letter-spacing: .2rem;

                .icon {
                    animation: bouncing .6s ease-in-out infinite;
                    animation-direction: alternate;
                }

                @keyframes bouncing {
                    from {
                        transform: translateY(10px);
                    }

                    to {
                        transform: translateY(-10px);
                    }
                }
            }
        }
    }
}

//Iphone
@media screen and (max-width: 350px) {
    .main-title {
        h1 {
            font-size: .7rem;
            letter-spacing: .2rem;
        }

        img {
            height: 15rem;
            width: 18rem;
        }
    }

    .banner {


        .text-at-bottom {
            bottom: 4rem;

            p {
                font-size: .8rem;
            }

            span {
                font-size: .5rem;
                letter-spacing: .1rem;
            }
        }
    }
}

//Iphone
@media screen and (min-width: 351px) and (max-width: 599px) {
    .main-title {
        h1 {
            font-size: .75rem;
            letter-spacing: .2rem;
        }

        img {
            height: 25rem;
            width: 28rem;
        }
    }

    .banner {


        .text-at-bottom {
            bottom: 4rem;

            p {
                font-size: 1rem;
            }

            span {
                font-size: .6rem;
                letter-spacing: .1rem;
            }
        }
    }
}

//Ipad and small tablet 
@media screen and (min-width: 600px) and (max-width: 1023px) {
    .main-title {
        h1 {
            font-size: 1.2rem;
            letter-spacing: .6rem;
        }

        img {
            height: 30rem;
            width: 34rem;
        }
    }

    .banner {


        .text-at-bottom {
            bottom: 6rem;

            p {
                font-size: 1.6rem;
            }

            span {
                font-size: .8rem;
                letter-spacing: .3rem;
            }
        }
    }
}

// Ipad pro 
@media screen and (min-width: 1023px) and (max-width: 1919px) {
    .main-title {
        h1 {
            font-size: 1.8rem;
            letter-spacing: .8rem;
        }

        img {
            height: 40rem;
            width: 45rem;
        }
    }

    .banner {


        .text-at-bottom {
            bottom: 6rem;

            p {
                font-size: 2rem;
            }

            span {
                font-size: .8rem;
                letter-spacing: .3rem;
            }
        }
    }
}

//desktop 
@media screen and (min-width: 1920px) {
    .main-title {
        h1 {
            font-size: 2rem;
            letter-spacing: 1rem;
        }

        img {
            height: 40rem;
            width: 45rem;
        }
    }

    .banner {


        .text-at-bottom {
            bottom: 6rem;

            p {
                font-size: 2rem;
            }
        }
    }
}