.about {
    width: 100%;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: white;

    .content {
        height: auto;
        line-height: 200%;
        font-weight: 600;
        font-family: 'Poiret One', cursive;
        color: black;
        display: flex;
        flex-direction: column;
        margin: auto;

        .img__container {
            border-radius: 50%;
            width: 12rem;
            height: 12rem;
            overflow: hidden;
            align-self: center;

            .img {
                object-fit: cover;
                width: 12rem;
                
            }
        }


        .paragraph {
            text-align: left;
            font-size: 1.1rem;

            span {
                font-family: 'Silkscreen', 'sans-serif';
                font-size: 1rem;
                color: rgb(70, 70, 70);
            }
        }

        .info {
            text-align: right;

            .name {
                font-family: 'Montserrat', cursive;
                font-size: 1.5rem;

                .github-i {
                    position: relative;
                    top: 3px;
                    height: 1.5rem;
                    width: 1.5rem;
                }
            }

            font-family: 'Silkscreen',
            'sans-serif';
            color: rgb(70, 70, 70);

            a {
                color: black;
                width: 10px;
                transition: opacity 0.2s ease-in-out;
            }

            a:hover {
                opacity: 0.8;
            }
        }
    }
}


//Iphone
@media screen and (max-width: 599px) {
    .about {
        height: 150vh;

        .content {
            width: 20rem;
            font-size: .8rem;
        }
    }
}

//Ipad and small tablet 
@media screen and (min-width: 600px) and (max-width: 1023px) {
    .about {
        height: 80vh;

        .content {
            width: 30rem;
            font-size: .8rem;
        }
    }
}

// Ipad pro 
@media screen and (min-width: 1023px) and (max-width: 1919px) {
    .about {
        height: 75vh;

        .content {
            width: 45rem;
            font-size: .9rem;
        }
    }
}

//desktop 
@media screen and (min-width: 1920px) {
    .about {
        height: 75vh;

        .content {
            width: 50rem;
            font-size: .9rem;
        }
    }
}