.back-to-top {
    position: fixed;
    bottom: 2%;
    right: 2%;
    z-index: 1000;

    .button {
        background: none;
        border: none;

        .icon {
            font-size: 2rem;
            color: #2e2e2e;
            transition: opacity 0.3s ease-in-out;

            &:hover {
                opacity: 0.5;
                cursor: pointer;
            }
        }
    }
}