@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@200&display=swap');

.project-content {
    position: relative;
    width: 100%;
    padding-top: 3rem;
    margin: 5rem auto;
    font-family: 'Montserrat', sans-serif;
    line-height: 200%;
    color: black;

    .intro {
        display: flex;
        justify-content: center;
        align-items: center;

        .logo {
            padding-left: 1rem;
        }

        .text {

            h2 {
                text-transform: uppercase;
            }

            span {
                font-weight: bolder;
                font-size: larger;
                font-family: 'Silkscreen', 'sans-serif';
                font-size: 1rem;
                color: rgb(27, 27, 27);
            }

            p {
                font-weight: 600;
                font-family: 'Poiret One', cursive;
                font-size: 1.1rem;
                color: rgb(27, 27, 27);
            }
        }
    }

    .projects {
        margin: 3rem auto auto auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90vw;


    }
}

//Iphone
@media screen and (max-width: 600px) {

    .project-content {
        padding-top: 0;
    }
    .intro {
        flex-direction: column;
        margin: 1rem;

        .logo {
            height: 10rem;
        }

        .text {
            margin-left: 0;
            text-align: center;
        }
    }

    .projects {
        flex-wrap: wrap;
        margin: auto;

        .project {
            margin: 0.5rem;
        }
    }
}


// Ipad and small tablet 
@media screen and (min-width: 601px) and (max-width: 1919px) {
    .intro {
        margin: 0 2.5rem;

        .logo {
            height: 15rem;
        }

        .text {
            margin-left: 1rem;
            text-align: left;
        }
    }

    .projects {
        flex-wrap: wrap;

        .project {
            margin: 2rem;
        }
    }
}

//desktop 
@media screen and (min-width: 1920px) {
    .intro {
        margin: 0 2rem;

        .logo {
            height: 15rem;
        }

        .text {
            margin-left: 5rem;
            text-align: left;
        }
    }

    .projects {
        flex-wrap: wrap;
        gap: 2rem;
       margin: auto;

    }
}