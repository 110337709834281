@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@200&display=swap');

.navbar {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 2.5rem;
  color: rgb(27, 27, 27);
  background: white;

  .intro {
    font-family: 'Poiret One', cursive;
    font-weight: bold;
    font-size: 1rem;
    padding-left: 15px;
  }

  .nav-menu {
    display: flex;
    margin-right: 5rem;

    .nav-link {
      margin: 0px 0.6rem;
      font-family: Montserrat;
      font-weight: 600;
      letter-spacing: .1rem;
      color: rgb(27, 27, 27);

      .nav-item {
        cursor: pointer;
        position: relative;
  
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -5px;
          width: 0;
          height: 2px;
          background-color: rgb(27, 27, 27);
          transition: width 0.4s ease-in-out, transform 0.4s ease-in-out;
          transform-origin: left;
        }
    
        &:hover::after {
          width: 100%;
          transform-origin: left;
        }

        &:not(:hover)::after {
          width: 100%;
          transform: scaleX(0);
          transform-origin: right;
        }

        &-linkedin {
          cursor: pointer;
          font-size: 1rem;
          position: relative;
          top: -1px;
          color: #2e2e2e;
          transition: opacity 0.2s ease-in-out;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }

  .nav-btn {
    color: #2e2e2e;
    border: none;
    background: none;
    .bars {
      height: 1rem;
      width: 1rem;
    }
  }

  .nav-close-btn {
    position: absolute;
    top: 5%;
    right: 5%;
    margin-right: 0;
  }

  .nav-btn:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 1023px) {
  .nav-menu {
    position: fixed;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    top: -100vh;
    gap: 2rem;
    opacity: 0;
    background: white;
    transition: all .6s ease-in-out;

    .nav-link {
      font-size: 1.5rem;
    }
  }

  .responsive_nav {
    top: 0;
    opacity: 1;
    transition: all .6s ease-in-out;
  }

  .nav-btn {
    display: initial;
  }

  @media screen and (max-width: 599px) {
    .intro {
      font-size: .8rem;
      letter-spacing: 0rem;
    }

    .nav-btn {
      font-size: .8rem;
      margin-right: 1.5rem;
    }
    .nav-menu > .nav-link > .nav-item-linkedin > .linkedin-i {
      font-size: 1.5rem;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 1023px) {
    .intro {
      font-size: 1rem;
      letter-spacing: .1rem;
    }

    .nav-btn {
      font-size: 1rem;
      margin-right: 5%;
    }

    .nav-menu > .nav-link > .nav-item-linkedin > .linkedin-i {
      font-size: 1.5rem;
    }
  }
}

@media screen and (min-width: 1023px) {
  .navbar {
    .intro {
      font-size: 1.2rem;
      letter-spacing: .2rem;
    }
  }

  .nav-menu {
    flex-direction: row;
    justify-content: space-around;

    .nav-link {
      font-size: .8rem;

    }
  }

  .nav-btn {
    display: none;
  }
}